//import LatestNewsOne from "../../components/blogs/LatestNewsOne";
import React, { useEffect, useState } from "react";
import Contact from "../../components/contact/Contact";
import CounterTwo from "../../components/counter/CounterTwo";
import FeatureImg from "../../components/features/FeatureImg";
import FeatureImgTwo from "../../components/features/FeatureImgTwo";
import Footer from "../../components/layout/footer/Footer";
import Navbar from "../../components/layout/header/Navbar";
import Layout from "../../components/layout/Layout";
import Subsribe from "../../components/newsletter/Subsribe";
import VideoSection from "../../components/play-video/video-section";
import Price from "../../components/prices/Price";
//import VideoPromo from "../../components/promo/VideoPromo";
import Screenshot from "../../components/screenshot/Screenshot";
// import Team from "../../components/team/Team";
import BrandCarousel from "../../components/testimonial/BrandCarousel";
import HeroEight from "./HeroEight";



export default function HomeEight() {

  const [loading, setLoading] = useState(true);

  //Component mounted
  useEffect(() => {

    setTimeout(() => { setLoading(false); }, 2000)

  }, [])


  return (
    <>
      {
        !loading ? (
          <>
            <Layout>
              <Navbar classOption="custom-header" />
              <HeroEight />
              <CounterTwo />
              <FeatureImgTwo />
              <FeatureImg ImgSource="assets/img/image-10.png" />
              <Price />
              <VideoSection />
              <Screenshot hasBg />
              <Contact bgColor="gray-light-bg" />
              <BrandCarousel />
              <Subsribe />
              <Footer space />
            </Layout>
          </>
        ) :
          <div style={{ marginTop: "50vh" }}>
            <center className="">
              <img
                src="assets/img/logo.png"
                width="100"
                alt="logo"
                className="img-fluid"
                style={{ display: "block" }}
              />
              <p className="spinner-border text-danger"></p>
            </center>
          </div>
      }

    </>
  );
}
