import React from "react";

export default function FaqStatic({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Privacy Policy</h2>
                  <p className="lead">
                    To use this Service, individuals must be at least 13 years old, or must have obtained parental or legal guardian consent if they are minors.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-12">
              <div className="single-faq">
                <h5>Introduction</h5>
                <p>
                  At KORSGY, we invest in the protection of all information entrusted to us through existing or
                  prospective business relations. This document describes our collection, usage, and sharing of
                  your information when you interact with us. Interactions with us can be through our websites,
                  mobile applications, online services (the "sites"); through our retail stores, communication in
                  person, by telephone, events. It also covers information we receive from our dealers,
                  distributors, service providers, business partners, and other third-party sources. Certain sites,
                  vehicles, products, and services may be subject to privacy notices separate from or that
                  supplement this policy, and can be found on or with the relevant sites, vehicles, products, or
                  services.
                </p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="single-faq">
                <h5>HOW WE COLLECT INFORMATION</h5>
                <h5>Information we Collect</h5>
                <p>
                  We may collect contact and identification information such as name, address, email address,
                  telephone number, account login information (including, if applicable, social media account
                  information for login purposes), signature, driver’s license information, and social security
                  number
                </p>
                <h5>Information you provide to us</h5>
                <p>
                  Some examples of when you provide us information include when you submit information on
                  our sites; when you participate in an event, sweepstakes, contest, or promotion; when you
                  purchase vehicles, products, or services on our sites or in our retail stores; and when you
                  communicate with us. Some examples of the types of information you provide us include
                  contact and identification information; demographic information; financial information;
                  information about your vehicles and products; and audio, electronic, or visual information
                </p>
                <h5>Device and usage information.
                </h5>
                <p>
                  We may collect technical data about the browsers and devices you use to access our sites,
                  emails, and advertisements, such as device type, browser type and language, hardware model,
                  operating system version, unique device identifiers, IP address, device event information (e.g.
                  hardware settings, date and time of your request, referral URL, crashes, and system activity).
                  We may also collect information about how you interact with our sites, emails, and
                  advertisements, such as features used, content viewed, and the dates and times of your
                  interactions. This information is collected automatically as you access the sites, emails, and
                  advertisements.

                </p>
                <h5>CONTACT US</h5>
                <p>
                  If you have any questions about this policy, please contact us using the contact information
                  below:<br></br>
                  By email: <a href="mailto:privacy@korsgy.com ">privacy@korsgy.com </a><br></br>
                  KORSGY Limited and its affiliates (collectively, “KORSGY,” “we,” “our,” and “us”) have a
                  long-standing commitment to safeguard the privacy of information entrusted to us and we
                  value the trust of our existing and prospective business relations, which include KORSGY’s
                  business customers, investors, business partners, resellers, suppliers and other relevant
                  stakeholders. The privacy principles and procedures set forth below are intended to tell you
                  how we collect, use, and distribute your information.

                </p>
                <h5>Collection and Use of Personal Information</h5>
                <p>
                  We may process personal information that identifies you as an individual or that relates to an
                  identifiable person (“Personal Information”) such as:
                </p>
                <ul>
                  <li>* Personal Details and Contact Information: Name; address; e-mail; telephone and fax
                    details and other contact information; nationality; photograph, voice recordings, and
                    video; signature and electronic signature; CVs received from vendors/subcontractor
                    specialists for projects;</li>
                  <li>* Professional Affiliations: Business name, title, and address;
                  </li>
                  <li>* Financial Information: Payment card number; bank account number and account details;
                    tax returns; salary; assets and income; personal bankruptcy; credit history and credit
                    score;
                  </li>
                  <li>* Marketing Preferences and Customer Service Interactions: Marketing preferences; entry
                    into contests or promotions; responses to voluntary surveys; recordings of telephone
                    calls with customer service and other representatives;</li>
                  <li>* Operational Data: Transactions, sales, purchases, uses, supplier information, credentials
                    to online services and platforms, and electronic content produced by data subjects
                    using company systems (including online interactive and voice communications) such as
                    blogs, chat, webcam use, and network sessions;</li>
                  <li>* Learning history, when you participate in online learning programs: Progress
                    dashboards, course, enrollment, and feedback reports;</li>
                  <li>*  Any further information required for credit verification, compliance/ethics checks, or
                    customer due diligence;</li>
                  <li>* Internal identifiers, to be able to process your Personal Information across our internal
                    systems.</li>
                </ul>
                <br></br>
                <p>We may collect Personal Information in various ways, such as: when you interact with one of our
                  websites; when you enter into a transaction or contractual arrangement with us; when you
                  participate in our programs or activities; when you provide data at industry events and trade
                  shows; when you visit our facilities or we visit you at your offices; when you contact our
                  customer service; or in connection with your inquiries and communications with us. We may
                  also collect Personal Information from other sources, including your employer, data companies,
                  publicly-accessible databases, and joint marketing partners. Additionally, KORSGY may
                  automatically collect or process Personal Information (such as your Internet domain or IP
                  address) as part of the operation of the services or during the course of your activities on or use
                  of the websites.
                </p>
                <br></br>
                <p>We and our service providers use Personal Information for legitimate business purposes
                  including:
                </p>
                <ul>
                  <li>* Providing the functionality of the services and fulfilling your requests.</li>
                  <li>* To respond to your inquiries and fulfill your requests, when you contact us via
                    one of our online contact forms or otherwise, for example, when you send us
                    questions, suggestions, compliments, or complaints, or when you request a
                    quote for or other information about our services.
                  </li>
                  <li>* To conclude and execute agreements with customers, suppliers, and business
                    partners, including required screening activities (e.g. for access to our premises
                    or systems), credit verification, compliance/ethics checks, or customer due
                    diligence.</li>
                  <li>* To complete your transactions, and provide you with related customer service.
                  </li>
                  <li>* To send administrative information to you, such as changes to our terms,
                    conditions, and policies.
                  </li>
                  <li>* To allow you to send messages to another person if you choose to do so.
                  </li>
                  <li>* We will engage in these activities where we have a legitimate interest, to
                    manage our contractual relationship with you, and/or comply with a legal
                    obligation.
                  </li>
                  <li>* Providing you with our newsletter and/or other marketing materials where you
                    have given consent.
                  </li>
                  <li>* To send you marketing-related emails, with information about our services, new
                    products, and other news about KORSGY as consented to.
                  </li>
                  <li>* We will engage in this activity with your consent or where we have a legitimate
                    interest.
                  </li>
                  <br></br>
                  <p>Analysis of Personal Information for business reporting and providing personalized services.
                  </p>
                  <li>* To analyze or predict our users’ preferences to prepare aggregated trend reports on how our digital content is used, so we can improve our services.

                  </li>
                  <li>* To better understand you, we can personalize our interactions with you and
                    provide you with information and/or offers tailored to your interests.
                  </li>
                  <li>* To better understand your preferences so that we can deliver content via our
                    services that we believe will be relevant and interesting to you.
                  </li>
                  <li>* We will provide personalized services either with your consent or because we
                    have a legitimate interest.
                  </li>
                  <br></br>
                  <p>Allowing you to participate in sweepstakes, contests, or other promotions.
                  </p>
                  <li>* We may offer you the opportunity to participate in sweepstakes, contests, or
                    other promotions.
                  </li>
                  <li>* Some of these promotions have additional rules containing information about
                    how we will use and disclose your Personal Information.
                  </li>
                  <li>* We use this information to manage our contractual relationship with you.
                  </li>
                  <br></br>
                  <p>Aggregating and/or anonymizing Personal Information</p>
                  <li>* We may aggregate and/or anonymize Personal Information so that it will no
                    longer be considered Personal Information. We do so to generate other data for
                    our use, which we may use and disclose for any purpose.
                  </li>
                  <li>* We engage in these activities because we have a legitimate interest.</li>
                  <br></br>
                  <p>Accomplishing our business purposes</p>
                  <li>* For data analysis, for example, to improve the efficiency of our services;</li>
                  <li>* For audits, to verify that our internal processes function as intended and are
                    compliant with legal, regulatory or contractual requirements;
                  </li>
                  <li>* For fraud and security monitoring purposes, for example, to detect and prevent
                    cyberattacks or attempts to commit identity theft;
                  </li>
                  <li>* For developing new products and services;
                  </li>
                  <li>* For enhancing, improving, or modifying our current products and services;
                  </li>
                  <li>* For identifying usage trends, for example, understanding which parts of our
                    services are of most interest to users;</li>
                  <li>* For determining the effectiveness of our promotional campaigns, so that we can
                    adapt our campaigns to the needs and interests of our users; and
                  </li>
                  <li>* For operating and expanding our business activities, for example, understanding
                    which parts of our services are of most interest to our users. This will enable us
                    to focus our energies on meeting our users’ interests.
                  </li>
                  <li>* We engage in these activities to manage our contractual relationship with you,
                    to comply with a legal obligation, and/or because we have a legitimate interest.
                  </li>

                </ul>
                <br></br>
                <h5>Sharing with other KORSGY companies</h5>
                <p>We may share your Personal Information with our affiliates for the purposes described in this
                  Privacy Statement.
                </p>
                <br></br>
                <h5>How Personal Information is shared with third-parties </h5>
                <p>KORSGY may disclose Personal Information to third parties assisting us in carrying out a
                  transaction or providing services for you (such as packaging, sending, and delivering purchases
                  and information to you; clearing credit card transactions; or performing statistical analyses of
                  our services). We may also disclose your Personal Information to our third-party service
                  providers who provide services such as website hosting, data analysis, payment processing,
                  order fulfillment, information technology, related infrastructure provision, customer service,
                  email delivery, auditing, and other services. <br></br>Other than with your consent, KORSGY will not sell, trade, or disclose your Personal
                  Information to third parties that want to market products to you.
                </p>
                <br></br>
                <h5>Other uses and disclosures </h5>
                <p>KORSGY may use or disclose Personal Information as we believe to be necessary or
                  appropriate, especially when we have a legal obligation or legitimate interest to do so: (a)
                  under applicable law, including laws outside your country of residence; (b) to comply with legal
                  process; (c) to respond to requests from public and government authorities including public
                  and government authorities outside your country of residence; (d) to enforce our terms and
                  conditions or in response to unlawful activities or a violation of KORSGY rules for use of the
                  Website; (e) to protect our operations or those of any of our affiliates; (f) to protect our rights,
                  privacy, safety or property, and/or that of our affiliates, you or others; and (g) to allow us to
                  pursue available remedies or limit the damages that we may sustain. In addition, we have a
                  legitimate interest to use, disclose or transfer your Personal Information to a third party in the
                  event of any reorganization such as a merger, a sale, a joint venture, an assignment, a transfer,
                  or other disposition of all or any portion of our business, assets or stock (including in
                  connection with any bankruptcy or similar proceedings). Such third-parties may include, for
                  example, an acquiring entity and its advisors.
                </p>
                <br></br>
                <h5>Sensitive Information</h5>
                <p>We do not knowingly collect sensitive Personal Information (such as but not limited to
                  information related to racial or ethnic origin, political opinions, religion or other beliefs, health,
                  biometrics or genetic characteristics, criminal background, or trade union membership) from
                  you via the services. We also ask that you do not send us, and you do not disclose to us, any
                  sensitive Personal Information on or through the services or otherwise. Where we collect
                  information that itself does not qualify as sensitive Personal Information, but from which
                  potentially sensitive inferences could be made (such as when you provide us with any dietary
                  requirements when attending one of our conferences or events), we only use such information
                  for the limited and non-sensitive purpose for which you made such information available to us.
                  We do not use such information to make any kind of sensitive inference.
                </p>
                <br></br>
                <h5>Other information we may collect, including ‘cookies’</h5>
                <p>“Other Information” is any information that does not reveal your specific identity or does not
                  directly relate to an individual, such as:</p>
                <ul>
                  <li>* Browser and device information;</li>
                  <li>* App usage data;
                  </li>
                  <li>* Information we collect through cookies, pixel tags, and other technologies;</li>
                  <li>* Demographic information and other such information you provide, that does not reveal
                    your specific identity;</li>
                  <li>* Information that has been aggregated in a manner that no longer reveals your specific
                    identity</li>
                </ul>
                <br></br>
                <h5>Collection of Other Information</h5>
                <p>We and our service providers may collect other information in a variety of ways, including:</p>
                <ul>
                  <li>* Through your browser or device: Certain information is collected by most browsers or
                    automatically through your devices, such as your Media Access Control (MAC) address,
                    computer type (Windows or Mac), screen resolution, operating system name and
                    version, device manufacturer and model, language, Internet browser type and version
                    and the name and version of the services (such as the app) you are using. We use this
                    information to ensure that the services function properly</li>
                  <li>* Use of cookies: We may use "cookies" in the operation of our websites. Cookies are
                    small pieces of information that are stored on your computer by the KORSGY Website
                    server</li>

                </ul>
                <br></br>
                <h5>Cookies Settings</h5>
                <p>Uses and Disclosures of Other Information<br></br>
                  We may use and disclose other information for any purpose, except where we are required to
                  do otherwise under applicable law. If we are required to treat other information as personal
                  information under applicable law, then we may use and disclose it for the purposes for which
                  we use and disclose personal information as detailed in this Privacy Statement.
                  In some instances, we may combine Other Information with Personal Information (such as
                  combining your name with your geographical location). If we combine any Other Information
                  with Personal Information, the combined information will be treated as Personal Information as
                  long as it is combined.</p>
                <br></br>
                <h5>Security measures
                </h5>
                <p>KORSGY has implemented technological and operational security processes to protect your
                  personally identifiable information from loss, misuse, alteration, or unintentional destruction.
                  While no security measure can guarantee the absence of compromise, KORSGY regularly
                  reviews and updates its security measures to provide appropriate security for all information
                  held by KORSGY. To ensure the security of personal data, KORSGY employs various technical,
                  physical, and organizational security measures; however, it is your responsibility to exercise
                  caution and reason when using the Services and Websites. You will be personally liable if your
                  use of the Services and Websites violates any third party privacy or any other rights or any
                  applicable laws. Under no circumstances is KORSGY liable for the consequences of your
                  unlawful, willful and negligent activities, and any circumstances that may not have been
                  reasonably controlled or foreseen (please read the General Terms for more information).If you
                  have reason to believe that your interaction with us is no longer secure, please immediately
                  notify us under the “Contact Us” section.
                </p>
                <br></br>
                <h5>Modifications to this Privacy Statement</h5>
                <p>KORSGY reserves the right to change, modify, or update this Privacy Statement at any time.
                  The “LAST UPDATED” legend at the top of this Privacy Statement indicates when this Privacy
                  Statement was last revised. Any changes will become effective when we post the revised
                  Privacy Statement on the services. Your use of the services following these changes means that
                  you accept the revised Privacy Statement.
                </p>
                <br></br>
                <h5>Your rights
                </h5>
                <p>KORSGY is committed to providing accurate information about its users. If you would like to
                  request access to Personal Information that you have previously provided to us, you may
                  contact us by using this form  <a href="https://www.thurvpn.com/#contact">https://www.thurvpn.com/#contact {" "}</a>
                  We will respond to your request where it is consistent with applicable law.
                  In your request, please make clear what Personal Information you would like to access and your
                  purpose for making the request. For your protection, we may only implement requests
                  concerning the Personal Information associated with the particular email address that you use
                  to send us your request, and we may need to verify your identity before implementing your
                  request.
                  Please note that we may need to retain certain information for record-keeping purposes and/or
                  to complete any transactions that you began before requesting a change or deletion (For
                  instance, when you make a purchase or enter a promotion, you may not be able to change or
                  delete the Personal Information provided until after the completion of such purchase. If you
                  wish to unsubscribe from our communication, you can opt out at any time by clicking the
                  “unsubscribe” link at the bottom of each email or contacting us at <a href="mailto:privacy@korsgy.com">privacy@korsgy.com</a>). Subject to those applicable data protection laws, among others, you may have the
                  following rights:</p>
                <ul>
                  <li>* Deletion: You may place a request to us to erase your personal data;</li>
                  <li>* Access: You may know and access personal data KORSGY has collected about you;
                  </li>
                  <li>* Rectification : You may rectify, correct, update, or complement inaccurate/incomplete
                    personal data KORSGY has about you;
                  </li>
                  <li>* Object: You may object to the processing of your personal data which is done on the
                    basis of our legitimate interests (e.g., for marketing purposes);
                  </li>
                  <li>* Portability: You may request us to provide you with a copy of your personal data in a
                    structured, commonly used and machine-readable format or to transmit (if technically
                    feasible) your personal data to another controller (only where our processing is based
                    on your consent and carried out by automated means);
                  </li>
                  <li>* Restriction: You may restrict the processing of your personal data (when there is a legal
                    basis for that);</li>
                  <li>* Withdrawal of consent: You may withdraw your consent where processing is based on a
                    consent you have previously provided;
                  </li>
                </ul>
                <br></br>
                <h5>Data retention
                </h5>
                <p>KORSGY will not retain your Personal Information longer than is necessary and permitted in
                  light of the purpose(s) for which it was obtained. The criteria used to determine our retention
                  periods include (i) the length of time we have an ongoing relationship with you; (ii) whether
                  there is a legal obligation to which we are subject; or (iii) whether retention is advisable in the
                  light of our legal position (such as applicable statutes of limitations, litigation or regulatory
                  investigations). Upon expiration or termination of your Account and/or Subscription, KORSGY
                  will immediately cease processing information that’s associated with you. However, please
                  note that there might be cases when we retain information associated with you after expiration
                  or termination of your Account and/or Subscription such as;
                </p>
                <ul>
                  <li>* Basic information (such as your email address) would still be visible in our system in
                    case you have another existing Account associated with a different KORSGY product.</li>
                  <li>* KORSGY also may retain information associated with you (e.g., payments data) in order
                    to fulfill its obligations as required by applicable laws, regulations, court orders,
                    subpoenas, or other legal processes for archival purposes.
                  </li>
                </ul>
                <br></br>
                <h5>Children
                </h5>
                <p>KORSGY takes children's privacy seriously. We do not direct our services to individuals under
                  the age of thirteen (13). We ask that children under the age of 13 do not send any personally
                  identifiable information to the services without the consent of their parents or guardians.
                  KORSGY encourages all parents and guardians to instruct their children in the safe and
                  responsible use of personal information on the Internet. KORSGY will not knowingly collect,
                  use, or disclose to third parties any Personal Information collected from children. We are
                  committed to complying with applicable laws and requirements, such as the United States'
                  Children's Online Privacy Protection Act (COPPA).
                </p>
                <br></br>
                <h5>Jurisdiction and cross-border transfer</h5>
                <p>In nearly all cases, your Personal Information may be collected or processed by, and transferred
                  to KORSGY facilities in the United States and in other countries where KORSGY does business.
                  Your Personal Information may be stored and processed in any country where we have facilities
                  or in which we engage service providers. By using the services, you consent to the transfer of
                  information to countries outside of your country of residence, including the United States,
                  which may have data protection rules that are different from those of your country. If you are a
                  resident of California , you can exercise your rights as provided in the California Consumer Privacy
                  Act (“CCPA”) by contacting us at <a href="mailto:privacy@korsgy.com">privacy@korsgy.com</a>. As per definitions in the CCPA,
                  please note that KORSGY does not sell, share, lease, or rent your personal information.
                  If you are a resident of EEA countries, you can exercise your rights as provided in the European
                  Union's General Data Protection Regulation (“GDPR”) by contacting us <a href="mailto:privacy@korsgy.com">privacy@korsgy.com</a>, some of the non-EEA countries are recognized
                  by the European Commission as providing an adequate level of data protection according to
                  EEA standards (the full list of these countries is available here
                  http://ec.europa.eu/justice/data-protection/international-transfers/adequacy/index_en.htm).
                  For transfers from the EEA to countries not considered adequate by the European Commission,
                  we have put in place adequate measures, such as standard contractual clauses adopted by the
                  European Commission to protect your Personal Information.
                </p>
                <br></br>
                <h5>Linked Websites</h5>
                <p>We may provide links to third-party sites. Since we do not control these websites, we
                  encourage you to review the privacy statement posted on these (and all third-party sites).
                  KORSGY is not responsible for the privacy statements or practices of sites and services
                  controlled by companies or organizations other than KORSGY. Inclusion of a link in our services
                  does not imply endorsement of the linked site or service by us or our affiliates.
                  In addition, we are not responsible for the information collection, use, disclosure, or security
                  policies or practices of other organizations, such as Facebook, Apple, Google, Microsoft, RIM,
                  or any other app developer, app provider, social media platform provider, operating system
                  provider, wireless service provider or device manufacturer, including concerning any Personal
                  Information you disclose to other organizations through or in connection with the apps or our
                  social media pages.
                </p>
                <br></br>
                <h5>Contact Information</h5>
                <p>If you have questions about this Privacy Statement, please contact us using this form <a href="mailto:privacy@korsgy.com">privacy@korsgy.com</a> . You can also find our contact details there. Email communications
                  are not always secure, so please do not include credit card or other sensitive information in
                  your emails to us.</p>
                <br></br>
                <h5>Additional information regarding the EEA</h5>
                <p>You may also Lodge a Complaint with a supervisory authority in your country or region or where an
                  alleged infringement of applicable data protection law occurs. A list of data protection
                  authorities is available at: <br></br>
                  <a href="http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080">http://ec.europa.eu/newsroom/article29/item-detail.cfm?item_id=612080</a></p>
              </div>
            </div>

          </div>
          {/* <div className="action-btn mt-4">
            <a target={'_blank'} rel="noreferrer" href="#" className="btn outline-btn">
              Read our Terms of Service
            </a>
          </div> */}

        </div>
      </section>
    </>
  );
}
