import React from "react";
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
export default function Footer({ space }) {
  const { t } = useTranslation();

  return (

    <>
      <footer className="footer-section">
        <div
          className={`footer-top background-img-2 ${space ? "pt-150" : "pt-60"}`}
          style={{
            background:
              "url('assets/img/footer-bg.png') no-repeat center top / cover",
          }}
        >
          <div className="container">
            <div className="row justify-content-between">
              <div className="col-md-12 col-lg-4 mb-4 mb-md-4 mb-sm-4 mb-lg-0">
                <div className="footer-nav-wrap text-white">
                  <img
                    src="assets/img/logo-w.png"
                    alt="footer logo"
                    width="120"
                    className="img-fluid mb-3"
                  />
                  <p>
                    {t('footer_ds')}
                  </p>

                  <div className="social-list-wrap">
                    <ul className="social-list list-inline list-unstyled">
                      <li className="list-inline-item">
                        <a href="https://www.facebook.com/profile.php?id=100093798665685&is_tour_completed=true" target="_blank" rel="noreferrer" title="Facebook" className="rounded-circle bg-white">
                          <img src="assets/img/Facebook.png" width={30} height={35} className="rounded-circle img-fluid" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://twitter.com/ThurVPN_" target="_blank" rel="noreferrer" title="Twitter" className="rounded-circle bg-white">
                          <img src="assets/img/Twitter.png" width={30} height={30} className="rounded-circle img-fluid" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.instagram.com/thurvpn_/" target="_blank" rel="noreferrer" title="Instagram" className="rounded-circle bg-white">
                          <img src="assets/img/Instagram.png" width={30} height={30} className="rounded-circle img-fluid" />
                        </a>
                      </li>
                      {/* <li className="list-inline-item">
                        <a href="https://www.youtube.com/channel/UCUeOWo8PlnUtl76vRZx49Zg" target="_blank" title="Youtube" className="rounded-circle bg-white">
                          <img src="assets/img/Youtube.png" width={30} height={30} className="rounded-circle img-fluid" />
                        </a>
                      </li> */}
                      <li className="list-inline-item">
                        <a href="https://www.linkedin.com/company/korsgy" target="_blank" title="Linked" className="rounded-circle bg-white">
                          <img src="assets/img/Linkedin.png" width={30} height={30} className="rounded-circle img-fluid" />
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a href="https://www.tiktok.com/@thurvpn" target="_blank" title="Tiktok" className="rounded-circle bg-white">
                          <img src="assets/img/Tiktok.png" width={30} height={30} className="rounded-circle img-fluid" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-md-12 col-lg-8">
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">{t('resource')}</h5>
                      <ul className="list-unstyled">
                        <li className="mb-2">
                          <a href="#contact">{t('help')}</a>
                        </li>
                        {/* <li className="mb-2">
                          <a href="#/">{t('event')}</a>
                        </li> */}
                        <li className="mb-2">
                          <a href="#/">{t('live_support')}</a>
                        </li>
                        {/* <li className="mb-2">
                          <a href="#/">{t('open_source')}</a>
                        </li> */}
                        <li className="mb-2">
                          <a href="#/">{t('documentation')}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4 mb-4 mb-sm-4 mb-md-0 mb-lg-0">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">{t('company')}</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2">
                          <a href="/">{t('home')}</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#downloads">{t('downloads')}</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#about">{t('about')}</a>
                        </li>
                        <li className="mb-2">
                          <a href="/#contact">{t('contact')}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-lg-4">
                    <div className="footer-nav-wrap text-white">
                      <h5 className="mb-3 text-white">{t('location')}</h5>
                      <ul className="list-unstyled support-list">
                        <li className="mb-2 d-flex align-items-center">
                          <i className="ti-location-pin mr-2"></i>
                          <span> 1011 Centre Road, Suite 104 Wilmington, DE 19805 </span>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-mobile mr-2"></span>
                          <a href="tel:+61283766284"> +1 302 483 7862 </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">
                          <span className="ti-email mr-2"></span>
                          <a href="mailto:support@thurvpn.com">
                            {" "}
                            support@thurvpn.com
                          </a>
                        </li>
                        <li className="mb-2 d-flex align-items-center">

                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom border-gray-light mt-5 py-3">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="copyright-wrap small-text">
                    <p className="mb-0 text-white">
                      @2023 © ThurVPN, {t('ars')}
                    </p>
                  </div>
                </div>
                <div className="col-md-6 col-lg-5">
                  <div className="terms-policy-wrap text-lg-right text-md-right text-left">
                    <ul className="list-inline">
                      <li className="list-inline-item">
                        <Link className="small-text" to={`/terms-privacy`}>{t('legals')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
