import React from "react";
import { useTranslation } from "react-i18next";
export default function CounterTwo() {
  const { t } = useTranslation();
  return (
    <>
      <section className="pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">50K</h3>
                <p className="mb-0">
                  {t('people_connected')}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">96% </h3>
                <p className="mb-0">
                  {t('satisfaction_rate_from')}
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-4 col-sm-6 mb-4 mb-md-4 mb-lg-0">
              <div className="single-fact p-3 text-center">
                <h3 className="title display-3 font-weight-bolder">4.9</h3>
                <p className="mb-0">
                  {t('avr_customer_rating')}
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
