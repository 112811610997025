import React from "react";
import { useTranslation } from "react-i18next";

export default function FeatureImgTwo() {
  const {t} = useTranslation()
  return (
    <>
      <section id="about" className="about-us ptb-100 gray-light-bg">
        <div className="container">
          <div className="row align-items-center justify-content-between">
            <div className="col-md-7">
              <div className="about-content-left section-heading">
                <h2>
                {t('we_believe_title')}
 <br />

                </h2>

                <div className="single-feature mb-4 mt-5">
                  <div className="icon-box-wrap d-flex align-items-center mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-1.png"
                        alt="icon "
                        className="img-fluid"
                      />
                    </div>
                    <p className="mb-0">
                    {t('we_believe_1')}
                    </p>
                  </div>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-2.png"
                        alt="icon "
                        className="img-fluid"
                        />
                    </div>
                    <p className="mb-0">
                        {t('we_believe_2')}
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-3.png"
                        alt="icon "
                        className="img-fluid"
                        />
                    </div>
                    <p className="mb-0">
                        {t('we_believe_3')}
                    </p>
                  </div>
                  <p></p>
                </div>
                <div className="single-feature mb-4">
                  <div className="icon-box-wrap mb-2">
                    <div className="mr-3 icon-box">
                      <img
                        src="assets/img/image-icon-4.png"
                        alt="icon "
                        className="img-fluid"
                        />
                    </div>
                    <p className="mb-0">
                        {t('we_believe_4')}
                    </p>
                  </div>
                  <p></p>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <div className="about-content-right">
                <img
                  src="assets/img/image-14.png"
                  alt="about us"
                  className="img-fluid"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
