import {useTranslation} from 'react-i18next';
import React, { useEffect, useState } from "react";
import Subscribe from "../../components/subscribe/Subscribe";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import BasicModal from '../modals/basicModal';
export default function VideoSection({ hasBg }) {
  
  const [open, setOpen] = useState(false);
  const videoUrl = "https://bucket-korsgy.fra1.digitaloceanspaces.com/THURVPN_CORRECTED.mp4_a517c9b4-f926-4f82-8aed-bd8abe691fe6.mp4"
  
  return (
    <>
    {/* <section style={{ height: '100%' ,width:'100%'}}>
      <video
       controls
        src={videoUrl}
        type="video/mp4"
        autoPlay
        loop 
        muted
        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
      />
    </section> */}
    </>
  );
}

// const styles = {
//   container:{
//     margin:'auto',
//     padding:2,
//     width:'50%'
//   },
//   customControlInput: {
//   transform: "scale(1.5)",
//   margin:'auto',
  
// },
// customControlLabel : {
//   transform:"scale(1.5)"
// }
// }