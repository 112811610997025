import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { useQuery } from "react-query";
import { getCountryCode } from "../../../repository/ipAddress";

const Navbar = ({ darkBg, classOption }) => {
  const [activeLink, setActiveLink] = useState('');
  const [scroll, setScroll] = useState(0);
  const [headerTop, setHeaderTop] = useState(0);
   useQuery(["getIp"], getCountryCode, {
    onSuccess: (code) => {
      if (languages.find((country) => country.code === code)) {
        i18next.changeLanguage(code);
      }
    },
  });
 
  const { t } = useTranslation();
  useEffect(() => {
    const stickyheader = document.querySelector(".header");
    setHeaderTop(stickyheader.offsetTop);
    const handleHashChange = () => {
      const hash = window.location.hash;
      setActiveLink(hash);
    };
    console.log("hash");

    window.addEventListener("scroll", handleScroll);
    window.addEventListener('hashchange', handleHashChange);


    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener('hashchange', handleHashChange);
    };


    
  }, []);
  const languages = [
    {
      code: "en",
      lname: "English",
      country_code: "gb",
    },
    {
      code: "fr",
      lname: "Français",
      country_code: "fr",
    },
    {
      code: "pt",
      lname: "Portuguese",
      country_code: "pt",
    },
    {
      code: "ru",
      lname: "Russia",
      country_code: "ru",
    },
    {
      code: "ar",
      lname: "عربي",
      country_code: "sa",
    },
    {
      code: "cn",
      lname: "Cantonese",
      country_code: "cn",
    },
  ];
  const handleScroll = () => {
    setScroll(window.scrollY);
  };
  return (
    <>
      <header className={`header ${classOption}`}>
        <nav
          className={`navbar navbar-expand-lg fixed-top ${
            darkBg ? "bg-transparent" : "custom-nav white-bg"
          } ${scroll > headerTop ? "affix" : ""}`}
        >
          <div className="container">
            <Link to="/" className="navbar-brand">
              {darkBg ? (
                <img
                  src="assets/img/logo-w.png"
                  width="120"
                  alt="logo"
                  className="img-fluid"
                />
              ) : (
                <img
                  src="assets/img/logo.png"
                  width="150"
                  alt="logo"
                  className="img-fluid"
                />
              )}
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="ti-menu"></span>
            </button>
            <div
              className="collapse navbar-collapse main-menu"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto f-md">
                <li className="nav-item ">
                  <HashLink to="#home" className={`nav-link ${activeLink == '#home'?'text-danger':''}`} >
                    {t("home")} {activeLink}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className={`nav-link ${activeLink == '#about'?'text-danger':''}`} smooth to="#about" activeClassName="text-danger">
                    {t("about")}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#features"activeClassName="text-danger">
                    {t("features")}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#pricing">
                    {t("pricing")}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#screenshots">
                    {t("screenshots")}
                  </HashLink>
                </li>
                <li className="nav-item">
                  <HashLink className="nav-link" smooth to="#contact">
                    {t("contact")}
                  </HashLink>
                </li>
                <li className="nav-item dropdown">
                  <Link
                    to="/"
                    className="nav-link  dropdown-toggle"
                    href="#"
                    id="navbarDropdownHome"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Lang - {i18next.language}
                  </Link>
                  <div
                    style={{ width: 200 }}
                    className="dropdown-menu submenu"
                    aria-labelledby="navbarDropdownHome"
                  >
                    {languages.map(({ country_code, code, lname }) => (
                      <Link
                        key={country_code}
                        to="/#"
                        onClick={() => i18next.changeLanguage(code)}
                        className="dropdown-item"
                      >
                        <span className={`fi fi-${country_code} mx-2`}></span>
                        {lname} 
                      </Link>
                    ))}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Navbar;
