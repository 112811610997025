import React from "react";
import { Link } from "react-router-dom"

export default function FaqStatic({ Title, space }) {
  return (
    <>
      <section className={`gray-light-bg  ${space ? "ptb-100" : "pb-60"}`}>
        <div className="container">
          {Title ? (
            <div className="row justify-content-center">
              <div className="col-md-9 col-lg-8">
                <div className="section-heading mb-5 text-center">
                  <h2>Privacy Policy</h2>
                  <p className="lead">
                    To use this Service, individuals must be at least 13 years old, or must have obtained parental or legal guardian consent if they are minors.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <div className="row">
            <div className="col-md-6">
              <div className="single-faq">
                <h5>NO LOGGING POLICY</h5>
                <p>
                  ThurVPN does not log any user activity, including browsing history, connection times, and IP addresses. We believe that privacy is a fundamental right, and we are committed to protecting it.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>STRONG ENCRYPTION</h5>
                <p>
                  ThurVPN uses strong encryption protocols, including AES-256, to secure all user traffic. This ensures that your data is protected from prying eyes, even if intercepted by a third-party
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>NO THIRD PARTY SHARING</h5>
                <p>
                  ThurVPN does not share any user data with third-party companies or advertisers. We do not sell or rent any user information, ensuring complete privacy and anonymity.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>KILL SWITCH</h5>
                <p>
                  ThurVPN includes a kill switch feature that immediately cuts off all internet traffic in the event of a VPN connection failure. This ensures that your IP address and activity remain secure at all times.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>DNS LEAK PROTECTION</h5>
                <p>
                  ThurVPN ensures that your DNS requests are routed through the VPN, preventing any potential leaks that could reveal your IP address or online activity.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="single-faq">
                <h5>TRANSPARENT PRIVACY POLICY</h5>
                <p>
                  ThurVPN has a transparent privacy policy that clearly outlines our commitment to user privacy and security. We are constantly reviewing and updating our policies to ensure that they remain up-to-date with the latest privacy regulations and best practices.
                </p>
              </div>
            </div>
          </div>

          <div className="action-btn mt-4">
            <Link to="/privacy-policy" className="btn outline-btn">
              Read Our Privacy Policy
            </Link>
          </div>

          <div className="action-btn mt-4">
            <a target={'_blank'} rel="noreferrer" href="https://bucket-korsgy.fra1.digitaloceanspaces.com/thurvpn_terms.pdf_7d4ed47d-1539-4123-87cc-0998bd2c2483.pdf" className="btn outline-btn">
              Read our Terms.pdf
            </a>
          </div>

        </div>
      </section>
    </>
  );
}
