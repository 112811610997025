import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CardElement } from '@stripe/react-stripe-js';
import {PaymentElement} from '@stripe/react-stripe-js';
import BasicModal from "../modals/basicModal";



export default function Subscribe({ bgColor ,plans, selectedPlanId}) {
  const { t } = useTranslation();

  const [open, setOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [subject] = useState("Customer");
  const [body, setBody] = useState("");
  const [pl, setPl] = useState(selectedPlanId);
  const [name, setName] = useState("");
  const [company, setCompany] = useState("Personal");

  const [error, setError] = useState("");

  const handleClick = () => {
   
    //check message body before sending
    if (!validateEmail(email)) {
      setError("Please provide a valid email");
      //alert("One or more support form item(s) yet to be filled, check and try again")
      return;
    }
    //form json data
    const jsonBody = {
      email: email,
      planId:pl
    };
    //do web-shot
    setError("Please wait...");
    fetch("https://api.thurvpn.com/api/users/pending-sub", {
      method: "post",
      headers: { "Content-Type": "application/json","ssk":"ce5ce9a240bfd7effabd741525ad23b0" },
      body: JSON.stringify(jsonBody)
    }).then((res) => {
      setOpen(true);
      const r = res.json().then((e)=>{
        setError("Your subscription record sent!");
        setTimeout(()=>{
          setError(null)
        },3000)
      })
    }).catch((e)=>{
      console.log(e);
    });
  };

  useEffect(()=>{
    setPl(selectedPlanId)
  },[selectedPlanId])

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <>
      <section
        id="Subscribe"
        className={`Subscribe-us ptb-100`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>{t("subscribe_title")}</h2>
                <p>{t("subscribe_ds")}</p>
              </div>
              {/* <div className="footer-address">
                <h5>{t("head_office")}</h5>
                <p> 1011 Centre Road, Suite 104 Wilmington, DE 19805 </p>
                <ul>
                  <li>
                    <span>{t("phone")}:+1 302 483 7862 </span>
                  </li>
                  <li>
                    <span>{t("fax")}:+1 302 483 7862 </span>
                  </li>
                  <li>
                    <span>
                      {t("email")} :
                      <a href="mailto:support@thurvpn.com">
                        support@thurvpn.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div> */}
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="Subscribe-us-form"
                onSubmit={(e) => {
                  e.preventDefault();
                  handleClick()
                  
                }}
              >
                <h5>{t("sub_proceed")}</h5>

                
                {/* <PaymentElement /> */}
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={t("enter_email")}
                        required="required"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 col-12">
                    <div className="form-group">
                      <select
                        name="subscription_type"
                        className="form-control"
                        id="subscription_type"
                        placeholder={t("select_subscription_type")}
                        value={pl}
                        onChange={(e) => setPl(e.target.value)}
                      >
                        {plans != null && plans.length > 0?
                        plans.map((plan)=>{
                          return (
                            <option key={plan._id} value={plan._id} >{plan?.title} ~ $ {plan?.price} </option>
                          )
                        })  
                      : <option>No plan loaded</option>}
                      
                      </select>
                    </div>
                  </div>
                  
                </div>
              <BasicModal isOpen={open} onClose={()=>setOpen(false)}>
              <section id="home" className="hero-section hero-section-3 ptb-100">
        <div className="circles">
          <div className="point animated-point-1"></div>
          <div className="point animated-point-2"></div>
          <div className="point animated-point-3"></div>
          <div className="point animated-point-4"></div>
          <div className="point animated-point-5"></div>
          <div className="point animated-point-6"></div>
        </div>
        <div className="container" id="animation-area-1">
          <div className="row align-items-center justify-content-center">
            <div className="col-12 col-md-9 col-lg-7 col-xl-6">
              <div className="hero-content section-title text-center text-lg-left my-5 mt-lg-0">
                <span className="text-uppercase color-secondary font-weight-bold">
                  {t('sim_download')}
                </span>
                <h1 className="font-weight-bold">
                {t("sub_download")}
                </h1>
                <p className="lead">
                {t('sub_download_des')}
                {" "}
                </p>
                <div className="action-btn mt-4">
                  <a target={'_blank'} rel="noreferrer" href="https://apps.apple.com/us/app/thurvpn/id6446090118?l=en" className="">
                    <img src={'assets/img/app-store.png'} alt={''} style={{width: '200px', cursor: 'pointer'}}/>
                  </a>
                  <a target={'_blank'} rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.korsgy.thurvpn" className="">
                    <img src={'assets/img/play-store.png'} alt={''} style={{width: '200px', cursor: 'pointer',}}/>
                  </a>
                  
                </div>
              </div>
            </div>
            <div className="col-9 col-md-7 col-lg-5 offset-xl-1 align-self-sm-end">
              <div className="hero-img position-relative">
                <div className="image-bg-positioning">
                  <img
                    src="assets/img/app-product.png"
                    alt=""
                    className="img-fluid"
                  />
                </div>
                <div className="animation-item d-none d-md-block d-lg-block">
                  <div className="p-4 w-75 rounded-custom d-flex white-bg hero-animated-card-1">
                    <p className="gr-text-1 mb-0 text-mirag-2">
                      {t('protect_data_thur')}
                    </p>
                    <div className="small-card-img ml-3">
                      <img
                        src="assets/img/team-2.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                  </div>
                  <div className="p-4 w-75 rounded-custom d-flex secondary-bg hero-animated-card-2">
                    <div className="small-card-img mr-3 text-white">
                      <img
                        src="assets/img/team-5.jpg"
                        alt=""
                        width="80px"
                        className="rounded-circle img-fluid"
                      />
                    </div>
                    <p className="gr-text-11 mb-0 text-white">
                      {t('protect_data_browse')}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
              </BasicModal>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn"
                      id="btnContactUs"

                    >
                      {" "}
                      {t("subscribe")}
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message">{error}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
