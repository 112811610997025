import React from "react";
//import Accordion from "../components/Privacy/Accordion";
import FaqStatic from "../components/Privacy/FaqStatic";
import Footer from "../components/layout/footer/Footer";
//import Navbar from "../components/layout/header/Navbar";
import Layout from "../components/layout/Layout";
import PageHeader from "../components/team/PageHeader";

export default function Faq() {
  return (
    <Layout>
      <PageHeader HeaderTitle="Terms & Privacy" Parent="Pages" PageTitle="Terms - Privacy" />
      {/*<Accordion Title />*/}
      <FaqStatic Title space />
      <Footer />
    </Layout>
  );
}
