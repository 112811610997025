import { connectIp , ipApiAccess, errorHandler} from "./ThurVpnNet";
import  axios  from 'axios';

export const getCountryCode = async ()=>{
try{
    const {ip } = await fetchIpAddress();

const result = await connectIp().get('/'+ip+'?access_key='+ipApiAccess)

return result.data.country_code;
}catch(e){
    return errorHandler(e);
}
    
}


const fetchIpAddress = async () => {
    const response = await axios.get("https://api.ipify.org/?format=json");
    return response.data
  };