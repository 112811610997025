import React, { useState } from "react";
import { useTranslation } from "react-i18next";

export default function Contact({ bgColor }) {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [subject] = useState("Customer");
  const [body, setBody] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [company, setCompany] = useState("Personal");

  const [error, setError] = useState("");

  const sendEmail = () => {
    const totalBody =
      "Message from ThurVPN site <br>" +
      "-----------------------------------------------<br><br>" +
      body +
      "<br><br>" +
      "Sender Name: " +
      name +
      "<br>" +
      "Sender Contact: " +
      phone +
      "<br>" +
      "Sender Company: " +
      company;
    //check message body before sending
    if (
      !validateEmail(email) ||
      subject === "" ||
      body === "" ||
      phone === "" ||
      company === "" ||
      name === ""
    ) {
      setError(
        "One or more support form item(s) yet to be filled, check and try again"
      );
      //alert("One or more support form item(s) yet to be filled, check and try again")
      return;
    }
    //form json data
    const jsonBody = {
      to: "support@thurvpn.com",
      subject: "ThurVPN Support | " + subject,
      body: totalBody
    };
    //do web-shot
    setError("Please wait...");
    fetch("https://api.thurvpn.com/api/feedback/send-mail", {
      method: "post",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(jsonBody)
    }).then((res) => {
      setError("Your message has been sent !");
    });
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
  return (
    <>
      <section
        id="contact"
        className={`contact-us ptb-100 ${bgColor ? "gray-light-bg" : ""}`}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 pb-3 message-box d-none">
              <div className="alert alert-danger"></div>
            </div>
            <div className="col-md-5">
              <div className="section-heading">
                <h2>{t("contact_us_title")}</h2>
                <p>{t("contact_ds")}</p>
              </div>
              <div className="footer-address">
                <h5>{t("head_office")}</h5>
                <p> 1011 Centre Road, Suite 104 Wilmington, DE 19805 </p>
                <ul>
                  <li>
                    <span>{t("phone")}:+1 302 483 7862 </span>
                  </li>
                  <li>
                    <span>{t("fax")}:+1 302 483 7862 </span>
                  </li>
                  <li>
                    <span>
                      {t("email")} :
                      <a href="mailto:support@thurvpn.com">
                        support@thurvpn.com
                      </a>
                    </span>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-7">
              <form
                action="#"
                method="POST"
                id="contactForm"
                className="contact-us-form"
                onSubmit={(e) => {
                  sendEmail();
                  e.preventDefault();
                }}
              >
                <h5>{t("reach_us")}</h5>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        id="name"
                        placeholder={t("enter_name")}
                        required="required"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="email"
                        placeholder={t("enter_email")}
                        required="required"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        id="phone"
                        placeholder={t("your_phone")}
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6 col-12">
                    <div className="form-group">
                      <input
                        type="text"
                        name="company"
                        
                        size="40"
                        className="form-control"
                        id="company"
                        placeholder={t("your_company")}
                        value={company}
                        onChange={(e) => setCompany(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <textarea
                        name="message"
                        id="message"
                        className="form-control"
                        rows="7"
                        cols="25"
                        placeholder={t("message")}
                        value={body}
                        onChange={(e) => setBody(e.target.value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-12 mt-3">
                    <button
                      type="submit"
                      className="btn solid-btn w-50"
                      id="btnContactUs"
                    >
                      {" "}
                      {t("send_message")}
                    </button>
                  </div>
                </div>
              </form>
              <p className="form-message">{error}</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
