import {useTranslation} from 'react-i18next';
import React, { useEffect, useState } from "react";
import Subscribe from "../../components/subscribe/Subscribe";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
export default function Price({ hasBg }) {
  const stripePromise = loadStripe('pk_test_TYooMQauvdEDq54NiTphI7jx');
  const {t} = useTranslation();
   const [isChecked, setIsChecked] = useState(true);
    const handlePriceView = (val) => {
    setIsChecked(val);
  };
  const [plans, setPlans] = useState([]);
  const [selectedPlanId, setSelectedPlanId] = useState(null);

  const [showSub,setShowSub] = useState(false);
  const [isHovered, setIsHovered] = React.useState(false);

  const handleClick = (planid) => {
    const element = document.getElementById('subscribe-container');
    element.scrollIntoView({ behavior: 'smooth' });
    setSelectedPlanId(planid);
    setShowSub(true)
  };

  const handleHover = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  const [options,setOptions] = useState(null)
  const jsonBody = {
    amount: "200",
  };
  const divStyle = {
    boxShadow: isHovered ? '0 8px 20px rgba(0, 0, 0, 0.2)' : '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'box-shadow 0.3s ease',
    cursor:'pointer',
    height:550
  };
  // useEffect(()=>{
  //   // fetch("https://api.thurvpn.com/api/purchases/create-sheet", {
  //   //   method: "post",
  //   //   headers: { "Content-Type": "application/json","ssk":"ce5ce9a240bfd7effabd741525ad23b0" },
  //   //   body: JSON.stringify(jsonBody)
  //   // }).then((res) => {
  //   //   res.json().then((e)=>{
        
  //   //     setOptions({clientSecret:e.data.paymentIntent})
  //   //   })
  //   // });
  //   fetch("https://api.thurvpn.com/api/plans/get-all", {
  //     method: "get",
  //     headers: { "Content-Type": "application/json","ssk":"ce5ce9a240bfd7effabd741525ad23b0" }
  //   }).then((res) => {
  //     res.json().then((e)=>{
  //       console.log(e);
  //       console.log("all plans ", e.data.plans)
  //       const plans  = e.data.plans;

        
  //       console.log(process.env.ssk)
  //       setPlans(e.data.plans);
  //     })
  //   });
  // },[])

  useEffect(() => {
    fetch("https://api.thurvpn.com/api/plans/get-all", {
      method: "get",
      headers: { "Content-Type": "application/json", "ssk": "ce5ce9a240bfd7effabd741525ad23b0" }
    })
      .then((res) => res.json())
      .then((e) => {
        const plans = e.data.plans;
  
        // Define the desired order of plan titles
        const desiredOrder = ["Basic Plan", "Standard Plan", "Professional Plan", "Basic Plan Ex", "Standard Plan Ex", "Professional Plan Ex"];
  
        // Reorder the plans based on the desired order
        const reorderedPlans = desiredOrder.map((title) => plans.find((plan) => plan.title === title));
  
        setPlans(reorderedPlans);
      });
  }, []);
  
  
  return (
    <>
    
      <div className="overflow-hidden">
        <section
          id="pricing"
          className={`package-section ${
            hasBg ? "gray-light-bg" : "background-shape-right"
          } ptb-100`}
        >
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-md-8">
                <div className="section-heading text-center mb-5">
                  <h2>
                  {t('pricing_title')}<br />
                    
                  </h2>
                  <p className="lead">
                  {t('pricing_ds')}
                  </p>
                </div>
              </div>
            </div>
      
            <div className="container m-auto justifyContent">
            <div className="row">
                <div className="col-0 mx-auto mb-3">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button  id="month-tab" className={isChecked?'nav-link ':' btn nav-link'} data-bs-toggle="tab" data-bs-target="#month" type="button" role="tab" aria-controls="month" onClick={()=>handlePriceView(true)} aria-selected="true">1 MONTH</button>
                </li>
                <li className="nav-item " role="presentation">
                  <button  className={!isChecked?'nav-link ':'nav-link btn'} id="year-tab" data-bs-toggle="tab"  data-bs-target="#year" type="button" role="tab" aria-controls="1year" onClick={()=>handlePriceView(false)} aria-selected="false">1 YEAR</button>
                </li>
              </ul>
              </div>
              </div>
            </div>
            {plans && plans.length > 0 &&
            <div className="tab-content" id="myTabContent">
              {isChecked? 
              
              <div className="row justify-content-center">
                                        
                          <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[0]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[0]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/basic.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                                <li>{t('fast_connect')}</li>
                                  <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>~</li>
                                  <li>~</li>
                                  <li>~</li>
                                  <li>~</li>
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[0]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                      {" "}
                      {t("subscribe")}
                    </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[1]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[1]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/standard.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                                <li>{t('fast_connect')}</li>
                                  <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>{t('secured_traffic')}</li>
                                  <li>{t('adlp')}</li>
                                  <li>~</li>
                                  <li>~</li>
                                  
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[1]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                      {" "}
                      {t("subscribe")}
                    </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[2]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[2]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/unlimited.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                              <li>{t('fast_connect')}</li>
                                  <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>{t('secured_traffic')}</li>
                                  <li>{t('adlp')}</li>
                                  <li>{t('server_up')}</li>
                                  <li>{t('upto_devices',{devices:12})}</li>
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[2]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                      {" "}
                      {t("subscribe")}
                    </button>
                              </div>
                            </div>
                          </div>
                        </div>
              :
              
                  <div className="row justify-content-center">
                                        
                                        <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[3]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[3]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/basic.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                                <li>{t('fast_connect')}</li>
                                  <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>~</li>
                                  <li>~</li>
                                  <li>~</li>
                                  <li>~</li>
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[3]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                                    {" "}
                                    {t("subscribe")}
                                  </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[4]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[4]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/standard.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                                <li>{t('fast_connect')}</li>
                                  <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>{t('secured_traffic')}</li>
                                  <li>{t('adlp')}</li>
                                  <li>~</li>
                                  <li>~</li>
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[4]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                                      {" "}
                                      {t("subscribe")}
                                    </button>
                              </div>
                            </div>
                          </div>
                          <div className="col-lg-4 col-md" onClick={()=>handleClick(plans[5]?._id)}  >
                            <div className="card text-center single-pricing-pack p-5" style={divStyle} onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
                              <h5 className="mb-2">{plans[5]?.title}</h5>
                              <div className="pricing-img mt-3 mb-4">
                                <img
                                  src="assets/img/unlimited.svg"
                                  alt="pricing img"
                                  className="img-fluid"
                                />
                              </div>

                              <div className="card-body p-0">
                                <ul className="list-unstyled text-sm pricing-feature-list">
                                <li>{t('fast_connect')}</li>
                                <li>{t('all_countries')} </li>
                                  <li>{t('unlimited_network')}</li>
                                  <li>{t('secured_traffic')}</li>
                                  <li>{t('adlp')}</li>
                                  <li>{t('server_up')}</li>
                                  <li>{t('upto_devices',{devices:12})}</li>
                                </ul>
                                <div className="py-4 border-0 pricing-header">
                                  <div className="h1 text-center mb-0 color-secondary">
                                    $<span className="price font-weight-bolder">{plans[5]?.price}</span>
                                  </div>
                                </div>
                                <button type="action"
                                      className="btn solid-btn w-75 "
                                      id="btnContactUs">
                                    {" "}
                                    {t("subscribe")}
                                  </button>
                              </div>
                            </div>
                          </div>
                        </div>
              
              }
              
            </div>}
          </div>
        </section>
        <section id='subscribe-container'>
        {/* {options && 
        showSub && <Elements stripe={stripePromise} options={options}> */}
      {showSub && <Subscribe bgColor="gray-light-bg" plans={plans} selectedPlanId={selectedPlanId} />}
      {/* </Elements>} */}
        </section>
      </div>
    </>
  );
}

// const styles = {
//   container:{
//     margin:'auto',
//     padding:2,
//     width:'50%'
//   },
//   customControlInput: {
//   transform: "scale(1.5)",
//   margin:'auto',
  
// },
// customControlLabel : {
//   transform:"scale(1.5)"
// }
// }