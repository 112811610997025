import React, { useState } from 'react';

const BasicModal = ({ isOpen, onClose, children }) => {

    const modalStyle = {
        display: isOpen ? 'block' : 'none',
        backgroundColor:"rgb(0,0,0,0.6)"
      };
    
  return (
        <div className={`modal ${isOpen ? 'show':''} `} tabIndex="-1" role="dialog" style={modalStyle} >
          <div className="modal-dialog modal-dialog-centered modal-xl border-0" role="document">
            <div className="modal-content">
              <div className="modal-header border-0">
                {/* <h5 className="modal-title">Modal Title</h5> */}
                <button type="button" className="close" onClick={onClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                {children}
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" onClick={onClose}>Close</button>
              </div>
            </div>
          </div>
          {/* <div className="modal-backdrop" onClick={onClose}></div> */}
        </div>
  );
};

export default BasicModal;